import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';

function MedicalTypeCheckBox({
  box,
  isEditMode,
  productTypes,
  setProductTypes,
  currentCheckList,
}) {
  let checkboxRef = useRef(null);
  useEffect(() => {
    currentCheckList.forEach((item) => {
      if (item === String(box)) {
        checkboxRef.current.checked = true;
      }
    });
  }, []);

  const onChange = (e) => {
    const checked = e.target.checked;
    checked
      ? setProductTypes([...productTypes, String(box)])
      : setProductTypes(productTypes.filter((item) => item !== String(box)));
  };

  return (
    <CheckBoxContainer>
      <Label>{box + 1}</Label>
      <StyledInput
        ref={checkboxRef}
        disabled={!isEditMode}
        onChange={onChange}
        type='checkbox'
      />
    </CheckBoxContainer>
  );
}

const CheckBoxContainer = styled(FlexWrap)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Label = styled.label``;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

export default MedicalTypeCheckBox;
