import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';

type Props = {
  isModifyNeeded: boolean;
  onClick: () => void;
};

function ModifyNeedCheckbox({ isModifyNeeded, onClick }: Props) {
  return (
    <CheckboxWrapper>
      <Checkbox marginBottom={32} onClick={onClick}>
        <CheckboxLabel>정보수정 필요</CheckboxLabel>
        <Icon
          imgUrl={isModifyNeeded ? checkedIcon : checkIcon}
          width='24px'
          height='24px'
          margin='0 0 1px 18px'
        />
      </Checkbox>
    </CheckboxWrapper>
  );
}

export default ModifyNeedCheckbox;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: default;
`;

const Checkbox = styled.div<{ marginBottom: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const CheckboxLabel = styled.p`
  font-weight: 600;
  user-select: none;
`;
